<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title="false">
    <a-spin :spinning="loading">
      <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="上访人姓名"
            :validateStatus="checkParams.titleStatus"
            :help="checkParams.titleStatusMsg"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入上访人姓名"
              v-decorator="['visitorName', {initialValue: queryParam.visitorName, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域"
            :required="true"
            :validateStatus="checkParams.areaCodeStatus"
            :help="checkParams.areaCodeStatusMsg"
          >
            <a-cascader
              style="width: 300px;"
              :options="regionList"
              placeholder="省-市-区(县)"
              :field-names="{label: 'label', value: 'value', children: 'children' }"
              :value="addDefaultRegion"
              @change="changeAddRegion"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="小区"
            :required="true"
            :validateStatus="checkParams.communityIdStatus"
            :help="checkParams.communityIdStatusMsg"
          >
            <a-select
              style="width:300px"
              placeholder="请选择小区"
              v-model="queryParam.communityId"
              @change="changeCommunityId"
            >
              <a-select-option :key="0" :value="0">请选择小区</a-select-option>
              <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                {{ item.communityName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="联系电话"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入联系电话"
              v-model="queryParam.contactPhone"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="举报人地址"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入举报人地址"
              v-model="queryParam.visitorAddress"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="上访原因"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入上访原因"
              v-model="queryParam.visitReason"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="详细说明"
          >
            <a-textarea
              style="width: 60%;"
              :rows="3"
              v-model="queryParam.description"
              placeholder="详细说明"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="陪同人员"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入陪同人员"
              v-model="queryParam.accompanyInfo"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="主管村干部/网格员信息"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入主管村干部/网格员信息"
              v-model="queryParam.supervisorInfo"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="预期结果"
          >
            <a-textarea
              style="width: 60%;"
              :rows="3"
              v-model="queryParam.expectation"
              placeholder="预期结果"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="是否已向相关部门反应"
          >
            <a-radio-group v-model="queryParam.isReportedBefore">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="是否已进行处理"
          >
            <a-radio-group v-model="queryParam.isSupervisorHandled">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="是否越级上访"
          >
            <a-radio-group v-model="queryParam.isLevelSkiped">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              type="primary"
              @click="saveApi"
              :disabled="saveDisabled"
              :loading="saveLoading"
            >
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import WangEditor from '@/components/Editor/WangEditor'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import { recordInfo, saveRecord } from '@/api/shangfang'
export default {
  name: 'AddRecord',
  components: {
    WangEditor
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addNoticeFrom' }),
      loading: false,
      saveDisabled: false,
      saveLoading: false,
      labelCol: {
        lg: { span: 3 },
        sm: { span: 6 }
      },
      wrapperCol: {
        lg: { span: 16 },
        sm: { span: 16 }
      },
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null
      },
      queryParam: {
        id: '',
        keyId: 0,
        communityId: 0,
        areaCode: 0,
        cityId: 0,
        provinceId: 0,
        visitorName: '',
        visitorAddress: '',
        visitReason: '',
        contactPhone: '',
        isReportedBefore: 0,
        isLevelSkiped: 0,
        accompanyInfo: '',
        supervisorInfo: '',
        isSupervisorHandled: 0,
        expectation: '',
        description: '',
        region: [0, 0, 0]
      },
      content: '',
      regionList: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      addDefaultRegion: []
    }
  },
  created () {
    this.regionListApi()
    if (this.$route.query.recordId !== 0 && this.$route.query.recordId !== undefined && this.$route.query.recordId !== '') {
      this.queryParam.keyId = parseInt(this.$route.query.recordId)
    }

    if (this.queryParam.keyId > 0) {
      this.recordInfoApi()
    } else {
      this.queryParam = {
        id: '',
        keyId: 0,
        communityId: 0,
        areaCode: 0,
        cityId: 0,
        provinceId: 0,
        visitorName: '',
        visitorAddress: '',
        visitReason: '',
        contactPhone: '',
        isReportedBefore: 0,
        isLevelSkiped: 0,
        accompanyInfo: '',
        supervisorInfo: '',
        isSupervisorHandled: 0,
        expectation: '',
        description: '',
        region: [0, 0, 0]
      }
    }
  },
  methods: {
    titleCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.visitorName = value.trim()
      if (_this.queryParam.visitorName !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入上访人姓名'
        callback(new Error('请输入上访人姓名'))
      }
    },
    wangEditorChange (e) {
      this.content = e
      this.queryParam.content = e
      console.log('wangEditorChange', this.content)
    },
    saveApi () {
      console.log('saveApi')
      const _this = this
      console.log(_this.queryParam)
      if (_this.queryParam.visitorName !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入上访人姓名'
        return false
      }
      if (this.queryParam.areaCode === '' || this.queryParam.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }
      if (this.queryParam.communityId === '' || this.queryParam.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
      saveRecord(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$router.push({ path: '/shangfang/record-list' })
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    recordInfoApi () {
      const _this = this
      _this.loading = true
      recordInfo({ keyId: _this.queryParam.keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.queryParam = res.result
          _this.content = res.result.content
          _this.addDefaultRegion = _this.queryParam.region
          _this.regionCommunityApi(_this.queryParam.areaCode, 1)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', _this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaCode = e[2] ? e[2] : 0
      if (this.queryParam.areaCode === '' || this.queryParam.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }

      this.regionCommunityApi(this.queryParam.areaCode, 1)
      this.queryParam.communityId = 0
    },
    changeCommunityId () {
      if (this.queryParam.communityId === '' || this.queryParam.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
    }
  }
}
</script>
